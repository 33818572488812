// Actions
const START_SESSION = "START_SESSION"

//Action Creator
export const startSession = (payload) =>{
    return {
        type:START_SESSION,
        payload:payload
    }
}

//Reducer 
const startNewSession = (state,payload) =>{
    console.log("New Session Started")
    let authUser = JSON.parse(localStorage.authUser);
    return authUser
}



const initialState = {}
const authReducer = (state = initialState, {type,payload}) =>{
    switch(type){
        case START_SESSION:
            let newState = startNewSession(state,payload);
            return newState;
        default:
            return state;
    }
    
}


export default authReducer;
