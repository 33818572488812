//Actions 

const GET_MASTER = "GET_MASTER";


//Action Creator
export const getMaster = (payload) =>{
    return {
        type:GET_MASTER,
        payload
    }
}


//Reducer
const initialState = {};

const masterReducer = (state= initialState, {type,payload}) =>{
    return state;
}

export default masterReducer;

