import { combineReducers } from "redux";
import authReducer from "./authStore";
import masterReducer from "./masterStore";


const rootReducer = combineReducers({
    auth:authReducer,
    master:masterReducer
})

export default rootReducer;