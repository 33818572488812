import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react'

const withAlert = OrignalComponent => (props) =>{
    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");
    const alertHandler = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }
    return (
        <>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={5000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>
            <OrignalComponent {...props} AppAlert={alertHandler} />
        </>
    )
}

export default withAlert;