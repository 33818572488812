import React from 'react'
import { Skeleton } from '@mui/material'

export default function CommonLoadingSkeleton() {
  return (
    <>
            <div className="card p-2 m-2">
                <Skeleton
                    animation="wave"
                    height={50}
                    width="30%"
                />
                <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                />
                <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                />
            </div>
    </>
  )
}