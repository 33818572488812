import React from 'react'

export default function Footer() {
  return (
    <>
    <div className="row" style={{ position:"fixed", bottom:0, right:0,left:0 }}>
      {/* 
      <div className="row bg-primary">
        <div className="col-md-6 offset-md-3">Footer</div>
      </div>
      */
      }
    </div>
    </>
  )
}

