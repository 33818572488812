import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function Logout() {
  const navigate = useNavigate()
  useEffect(()=>{
    localStorage.clear();
    navigate("/auth/login")
  },[navigate])
  return (
    <div>Logout</div>
  )
}
