import { Delete } from '@mui/icons-material';
import { IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import api from "../../api/api"

export default function FileUploader(props) {
    const {label,name,className, formik} = props;
    const [file,setFile] = useState(null)

    const [uploadProgress, setUploadProgress] = useState(null);
    const [error,setError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    
    const [isImage, setIsImage] = useState(false);
    const [uploadedFileName, setFileName] = useState("");
    const [filePath, setFilePath] = useState("");
    
    const startUpload = () =>{
        let data = new FormData();
        data.append('tmpFile', file);
        api.post("upload/document",data,{
            onUploadProgress: (progressEvent) => { 
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                //console.log(percent);
                setUploadProgress(percent)
            }
        }).then( (res)=>{
            //console.log(res.data);
            setFileName(res.data.fileName)
            setFilePath(res.data.filePath)
            setFileType (res.data.fileType)
            
            formik.setFieldValue(name, res.data.fileName);
            //formik.setTouched(name,true)

        }).catch( (error) => {
            console.log(error);
            setError(true)
            setErrorMsg(error);
        })
        
    }

    const setFileType = (fileType) => {
        fileType = fileType.toLowerCase();
        switch(fileType){
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
            case "bmp":
            case "ico":
                setIsImage(true);
                break;
            default:
                setIsImage(false);
        }
    }

    const removeAttachment = () =>{
        setFile(null)
        setUploadProgress(null);
        setError(false);
        setErrorMsg("");
        setIsImage(false);
        setFileName("");
        setFilePath("");
        formik.setFieldValue(name, "");
    }

    let fileName = formik.values[name]; // Getting Existing Values
    useEffect(()=>{
        if(fileName){
            let arr  = fileName.split(".");
            let type = arr[arr.length-1];
            setFileType(type);

            setFileName(fileName);

            let documentURL = process.env.REACT_APP_BASE_URL+"/uploads/documents/"+fileName;
            setFilePath(documentURL)
            
            setUploadProgress(100);
        } else {
            setIsImage(false);
            setFileName("");
            setFilePath("");
            setUploadProgress(null);
        }
        
    },[fileName])


    return (
        <>
            <div className="card p-3">
                <div className={className}>
                        { !error && uploadProgress === null &&
                            <>
                                <input id="uploadBtn" type="file" className="upload" onChange={ (e)=>{ setFile(e.target.files[0]) } } />
                                <button type="button" className="btn btn-primary" onClick={startUpload} >Upload</button>
                            </>
                        }
                        { !error && uploadProgress !== null && uploadProgress < 100 && <LinearProgress variant="determinate" value={uploadProgress} /> }
                        { !error && uploadProgress !== null && uploadProgress === 100 && isImage && 
                            <>
                                <img className="img-thumbnail w-25" src={filePath}/> <a target="_blank" href={filePath}>{uploadedFileName}</a> 
                                <IconButton onClick={removeAttachment} sx={{color:"#A00"}}>
                                    <Delete />
                                </IconButton>
                            </> 
                        }
                        { !error && uploadProgress !== null && uploadProgress === 100 && !isImage && 
                            <>
                                <a target="_blank" href={filePath}>{uploadedFileName}</a> 
                                <IconButton onClick={removeAttachment}  sx={{color:"#A00"}}>
                                    <Delete />
                                </IconButton>
                            </>
                        }
                        {error && <p>{errorMsg}</p>}
                </div>
            </div>
        </>
    )
}
